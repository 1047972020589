<template>
  <div id='main-top-navbar'>
    <div class="container">
      <div class="navigation-wrapper">
        <div class="left-col">
          <language-selector @langDropdownOpened="handleMobileMenuClose" />
          <ul class="navigations">
            <li v-for="(button, i) in leftColButtons" :key="i">
              <button-with-icon
                :iconUrl="button.iconUrl"
                :isActive="i == 0"
                :text="button.text"
                :dropdownItems="button.dropdownItems"
                @clicked="handleNavigationButtonClick(button.key)"
                @dropdownItemClicked="handleDropdownItemClick"
              />
            </li>
          </ul>
        </div>
        <div class="middle-col">
          <router-link to="/">
            <img class="logo" src="/static/images/logos/hungarospa-logo_colorfull.svg">
          </router-link>
        </div>
        <div class="right-col">
          <language-selector />
          <button-cart />
          <user-dropdown />
          <icon-hamburger @click.native="handleHamburgerMenuClick" />
        </div>
      </div>
    </div>
    <mobile-menu-dropdown
      :isOpen="isMobileMenuOpen"
      :navigations="mobileMenuLinks"
      @closeDropdown="handleMobileMenuClose"
    />
  </div>
</template>

<script>
import ButtonWithIcon from '@/components/shared/elements/buttons/ButtonWithIcon';
import LanguageSelector from '@/components/shared/elements/selectors/LanguageSelector';
import ButtonCart from '@/components/shared/elements/buttons/ButtonCart';
import UserDropdown from '@/components/shared/modules/users/UserDropdown';

export default {
  name: 'MainTopNavbar',
  props: {},
  components: {
    ButtonWithIcon,
    LanguageSelector,
    ButtonCart,
    UserDropdown,
    'mobile-menu-dropdown': () => import('@/components/shared/elements/partials/MobileMenuDropdown'),
    'icon-hamburger': () => import('@/components/shared/elements/icons/IconHamburger'),
  },
  data: () => ({
    isMobileMenuOpen: false,
    spaPartsLink: 'https://www.hungarospa.hu/Furdokomplexum',
    hungarospaHomeLink: 'https://www.hungarospa.hu',
    thermalLink: 'https://www.hungarospa.hu/hu/Szallashelyek/Thermal-Tourist',
    thermalCampingLink: 'https://www.hungarospa.hu/Szallashelyek/Thermal-Camping',
  }),
  created() {},
  computed: {
    leftColButtons() {
      return [
        // {
        //   key: 'home',
        //   text: this.$t('navbar.navigations.home'),
        //   iconUrl: '/static/images/icons/home.svg',
        // },
        {
          key: 'map',
          text: this.$t('navbar.navigations.map'),
          iconUrl: '/static/images/icons/marker.svg',
        },
        {
          key: 'services',
          text: this.$t('navbar.navigations.services'),
          iconUrl: '/static/images/icons/tickets.svg',
          dropdownItems: [
            {
              key: 'spa',
              text: this.$t('navbar.navigationsDropdown.services.partsOfSpa'),
            },
            {
              key: 'hunSpa',
              text: this.$t('navbar.navigationsDropdown.services.hungarospaHome'),
            },
            {
              key: 'hunThermal',
              text: this.$t('navbar.navigationsDropdown.services.hungarospaThermal'),
            },
            {
              key: 'thermalCamp',
              text: this.$t('navbar.navigationsDropdown.services.thermalCamping'),
            },
          ],
        },
      ];
    },
    mobileMenuLinks() {
      return [
        {
          link: this.spaPartsLink,
          text: this.$t('navbar.mobile.navigations.spaParts'),
        },
        {
          link: this.hungarospaHomeLink,
          text: this.$t('navbar.mobile.navigations.hungarospaPage'),
        },
        {
          link: this.thermalCampingLink,
          text: this.$t('navbar.mobile.navigations.thermalCamping'),
        },
      ];
    },
  },
  methods: {
    handleNavigationButtonClick(btnKey) {
      // todo!!
      switch (btnKey) {
        case 'map':
          if (this.$route.name !== 'HomeScreen') {
            this.$router.push('/');
          }
          break;
        default:
          break;
      }
    },
    handleDropdownItemClick(key) {
      const objectIndex = this.leftColButtons.findIndex((item) => item.key === 'services');
      const object = this.leftColButtons[objectIndex];

      switch (key) {
        case 'spa':
          window.open(this.spaPartsLink, '_blank');
          break;
        case 'hunSpa':
          window.open(this.hungarospaHomeLink, '_blank');
          break;
        case 'hunThermal':
          window.open(this.thermalLink, '_blank');
          break;
        case 'thermalCamp':
          window.open(this.thermalCampingLink, '_blank');
          break;
        default:
          break;
      }
    },
    handleHamburgerMenuClick() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    handleMobileMenuClose() {
      this.isMobileMenuOpen = false;
    },
  },
};
</script>

<style lang='scss' scoped>
$navbarHeight: 88px;
$navbarHeightMobile: 77px;
$logoWidth: 185px;
$logoHeight: 62px;

#main-top-navbar {
  position: relative;
  z-index: z('navbar');

  @media screen and (max-width: $breakpointDownMd) {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: $globalBackgroundColor;
    z-index: z('mobile-navbar');
  }
}

#mobile-menu-dropdown {
  position: fixed;
  top: $navbarHeightMobile;
  left: 0;
  width: 100%;
}

.navigation-wrapper {
  display: grid;
  grid-template-columns: auto auto 1fr;
  height: $navbarHeight;
  align-items: center;

  @media screen and (max-width: $breakpointDownMd) {
    height: $navbarHeightMobile;
    grid-template-columns: 1fr auto 1fr;
  }
}

.logo {
  width: $logoWidth;
  height: $logoHeight;

  @media screen and (max-width: $breakpointDownLg) {
    width: 120px;
  }

  @media screen and (max-width: $breakpointDownMd) {
    width: 135px;
  }
}

.navigations {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;

  @media screen and (max-width: $breakpointDownMd) {
    display: none;
  }
}

.left-col {
  padding-right: 30px;

  @media screen and (max-width: $breakpointDownLg) {
    padding-right: 15px;
  }

  #language-selector {
    display: none;

    @media screen and (max-width: $breakpointDownMd) {
      display: inline-block;
    }

    &::v-deep {
      .dropdown-list {
        left: 0;
        transform: none;
      }
    }
  }
}

.right-col {
  padding-left: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: $breakpointDownLg) {
    padding-left: 15px;
  }

  #language-selector,
  .user-dropdown {
    @media screen and (max-width: $breakpointDownMd) {
      display: none;
    }
  }
}

.button-cart {
  @media screen and (max-width: $breakpointDownMd) {
    margin: 0 35px 0 auto;
  }

  @media screen and (max-width: $breakpointDownXXs) {
    margin-right: 10px;
  }
}

.icon-hamburger {
  display: none;
  cursor: pointer;

  @media screen and (max-width: $breakpointDownMd) {
    display: block;
  }
}
</style>
