<template>
  <div class='footer-socials'>
    <a v-if="facebookLink" target="_blank" :href="facebookLink">
      <font-awesome-icon :icon="['fab', 'facebook-square']"  />
    </a>
    <a v-if="pinterestLink" target="_blank" :href="pinterestLink">
      <font-awesome-icon :icon="['fab', 'pinterest-square']"  />
    </a>
    <a v-if="instagramLink" target="_blank" :href="instagramLink">
      <font-awesome-icon :icon="['fab', 'instagram-square']"  />
    </a>
    <a v-if="youtubeLink" target="_blank" :href="youtubeLink">
      <font-awesome-icon :icon="['fab', 'youtube-square']"  />
    </a>
  </div>
</template>

<script>
export default {
  name: 'FooterSocials',
  props: {
    facebookLink: {
      type: String,
      required: false,
    },
    pinterestLink: {
      type: String,
      required: false,
    },
    instagramLink: {
      type: String,
      required: false,
    },
    youtubeLink: {
      type: String,
      required: false,
    },
  },
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang='scss' scoped>
$iconSize: 26px;

.footer-socials {
  display: flex;
  align-items: center;
  justify-content: center;

  a,
  a:visited {
    font-size: $iconSize;
    color: $white;
    margin: 0 20px 0 0;

    &:last-child {
      margin: 0;
    }

    &:hover {
      margin-top: -5px;

      @media screen and (max-width: $breakpointDownMd) {
        margin-top: 0;
      }
    }
  }
}
</style>
