import api from '@/api/cart';
// import store from '@/store';

const saveSelectedDate = (context, date) =>
  new Promise((resolve) => {
    context.commit('saveSelectedDate', date, { root: true });
    resolve(true);
  });

const createCart = (context) =>
  new Promise((resolve, reject) => {
    api
      .createCart()
      .then((response) => {
        if (response.data.firstName !== undefined) {
          context.commit('storeCartId', response.data.cart, { root: true });
        } else {
          context.commit('storeCartId', response.data._id, { root: true });
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

const getCart = (context, cartId) =>
  new Promise((resolve, reject) => {
    api
      .getCart(cartId)
      .then((response) => {
        context.commit('SAVE_CURRENT_CART', response.data);
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

const setCartItem = (context, { cartId, requestObj }) =>
  new Promise((resolve, reject) => {
    api
      .setCartItem(cartId, requestObj)
      .then((response) => {
        context.commit('SAVE_CURRENT_CART', response.data);
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

const addCartItem = (context, { cartId, requestObj, store = true }) =>
  new Promise((resolve, reject) => {
    api
      .addCartItem(cartId, requestObj)
      .then((response) => {
        if (store) {
          context.commit('SAVE_CURRENT_CART', response.data);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

const removeCartItem = (context, { cartId, cartItemId }) =>
  new Promise((resolve, reject) => {
    api
      .removeCartItem(cartId, cartItemId)
      .then((response) => {
        context.commit('SAVE_CURRENT_CART', response.data);
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

const switchCartItems = (context, cartId) =>
  new Promise((resolve, reject) => {
    api
      .switchCartItems(cartId)
      .then((response) => {
        context.commit('SAVE_CURRENT_CART', response.data);
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

const clearCart = (context, cartId) =>
  new Promise((resolve, reject) => {
    api
      .clearCart(cartId)
      .then((response) => {
        context.commit('SAVE_CURRENT_CART', response.data);
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

const attachInvoiceData = (context, { cartId, invoiceData }) =>
  new Promise((resolve, reject) => {
    api
      .attachInvoiceData(cartId, invoiceData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

const finalizeCart = (context, params) =>
  new Promise((resolve, reject) => {
    console.log(params);
    api
      .finalizeCart(params.cartId, params.provider)
      .then((response) => {
        context.commit('setOrderId', response.data.orderRef, { root: true });
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

const checkCartLimit = (context, requestObj) =>
  new Promise((resolve, reject) => {
    api
      .checkCartLimit(requestObj.cartId, requestObj.amount)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export default {
  saveSelectedDate,
  createCart,
  getCart,
  setCartItem,
  addCartItem,
  removeCartItem,
  switchCartItems,
  clearCart,
  attachInvoiceData,
  finalizeCart,
  checkCartLimit,
};
