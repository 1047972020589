const FACILITIES = {
  TYPE: {
    BEACH: 'strand',
    THERMAL: 'thermal',
    AQUA_PALACE: 'aquapalace',
    AQUA_PARK: 'aquapark',
    PREMIUM: 'premium',
    POOL: 'uszoda',
  },
  NAME_KEY: {
    BEACH: 'general.facilities.beach',
    THERMAL: 'general.facilities.thermal',
    AQUA_PALACE: 'general.facilities.aquaPalace',
    AQUA_PARK: 'general.facilities.aquaPark',
    PREMIUM: 'general.facilities.premium',
    POOL: 'general.facilities.pool',
  },
  COLOR: {
    BEACH: '#639AD2',
    THERMAL: '#3EAE58',
    AQUA_PALACE: '#E07024',
    AQUA_PARK: '#D22226',
    PREMIUM: '#E4D227',
    POOL: '#A370AE',
    DEFAULT: '#E07024',
  },
  THUMBNAIL: {
    BEACH: '/static/images/facilities/beach.jpg',
    THERMAL: '/static/images/facilities/thermal.jpg',
    AQUA_PALACE: '/static/images/facilities/aqua-palace.jpg',
    AQUA_PARK: '/static/images/facilities/aqua-park.jpg',
    PREMIUM: '/static/images/facilities/premium.jpg',
    POOL: '/static/images/facilities/pool.jpg',
    DEFAULT: '/static/images/facilities/beach.jpg',
  },
  ID_TO_KEY: {
    BEACH: '4',
    THERMAL: '11',
    AQUA_PALACE: '28',
    AQUA_PARK: '3',
    PREMIUM: '60',
    POOL: '37',
  },
  DESCRIPTION_KEYS: {
    BEACH: 'general.facilitesDescription.beach',
    THERMAL: 'general.facilitesDescription.thermal',
    AQUA_PALACE: 'general.facilitesDescription.aquaPalace',
    AQUA_PARK: 'general.facilitesDescription.aquaPark',
    PREMIUM: 'general.facilitesDescription.premium',
    POOL: 'general.facilitesDescription.pool',
  },
  FACILITIES_WITH_WARNING: [59],
};

export default FACILITIES;
