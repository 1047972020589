import { fetchData, apiURL } from './api_helper';

const createCart = () => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/cart/create`;

  fetchData(endpointURL, 'POST', {}).then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

const getCart = (cartId) => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/cart/${cartId}`;

  fetchData(endpointURL, 'GET').then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

const setCartItem = (cartId, requestObj) => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/cart/${cartId}/set`;

  fetchData(endpointURL, 'POST', requestObj).then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

const addCartItem = (cartId, requestObj) => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/cart/${cartId}/add`;

  fetchData(endpointURL, 'POST', requestObj).then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

const removeCartItem = (cartId, cartItemId) => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/cart/${cartId}/remove/${cartItemId}`;

  fetchData(endpointURL, 'DELETE').then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

const switchCartItems = (cartId) => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/cart/${cartId}/switchCartItems`;

  fetchData(endpointURL, 'POST', {}).then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

const clearCart = (cartId) => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/cart/${cartId}/clear`;

  fetchData(endpointURL, 'POST', {}).then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

const attachInvoiceData = (cartId, invoiceData) => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/cart/${cartId}/attachInvoiceData`;

  fetchData(endpointURL, 'POST', invoiceData).then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

const finalizeCart = (cartId, provider) => new Promise((resolve, reject) => {
  console.log(cartId);
  console.log(provider);
  const endpointURL = `${apiURL}/cart/${cartId}/finalize`;

  fetchData(endpointURL, 'POST', { provider }).then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

const checkCartLimit = (cartId, amount) => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/cart/${cartId}/checkCartLimit?cartItemCount=${amount}`;

  fetchData(endpointURL, 'GET').then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

export default {
  createCart,
  getCart,
  setCartItem,
  addCartItem,
  removeCartItem,
  switchCartItems,
  clearCart,
  attachInvoiceData,
  finalizeCart,
  checkCartLimit,
};
