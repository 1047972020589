import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';

// Screens
const HomeScreen = () => import('@/screens/HomeScreen');
const LoginScreen = () => import('@/screens/LoginScreen');
const RegistrationScreen = () => import('@/screens/RegistrationScreen');
const PdfScreen = () => import('@/screens/PdfScreen');
const RequestPasswordResetScreen = () => import('@/screens/RequestPasswordResetScreen');
const ResetPasswordScreen = () => import('@/screens/ResetPasswordScreen');
const ProfileScreen = () => import('@/screens/ProfileScreen');
const TicketsScreen = () => import('@/screens/TicketsScreen');
const CartScreen = () => import('@/screens/CartScreen');
const PaymentScreen = () => import('@/screens/PaymentScreen');
const PaymentFinalizationScreen = () => import('@/screens/PaymentFinalizationScreen');
const ServicesDownScreen = () => import('@/screens/ServicesDownScreen');
const PurchasesScreen = () => import('@/screens/PurchasesScreen');
const TermsAndConditionsScreen = () => import('@/screens/TermsAndConditionsScreen');
const PrivacyPolicyScreen = () => import('@/screens/PrivacyPolicyScreen');
const PageNotFound = () => import('@/screens/PageNotFound');

Vue.use(Router);

const enabledWithoutAuth = [
  'HomeScreen',
  'TicketsScreen',
  'CartScreen',
  'ResetPasswordScreen',
  'RequestPasswordResetScreen',
  'RegistrationScreen',
  'LoginScreen',
  'PaymentScreen',
  'ServicesDownScreen',
  'TermsAndConditionsScreen',
  'PrivacyPolicyScreen',
  'PdfScreen',
  'PageNotFound',
];

const disabledWithAuth = [
  'LoginScreen',
  'RegistrationScreen',
  'RequestPasswordResetScreen',
  'ResetPasswordScreen',
];

const guard = ((to, from, next) => {
  if (store.getters.authToken) {
    if (disabledWithAuth.includes(to.name)) {
      next('/');
      return;
    }
  } else if (!enabledWithoutAuth.includes(to.name)) {
    next('/users/login');
    return;
  }
  next();
});

const router = new Router({
  mode: 'history',
  // eslint-disable-next-line consistent-return
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 });
      }, 400);
    });
  },
  routes: [
    {
      path: '*',
      redirect: '/404',
    },
    {
      path: '/404',
      name: 'PageNotFound',
      component: PageNotFound,
    },
    {
      path: '/',
      name: 'HomeScreen',
      component: HomeScreen,
    },
    {
      path: '/users/registration',
      name: 'RegistrationScreen',
      component: RegistrationScreen,
    },
    {
      path: '/users/login',
      name: 'LoginScreen',
      component: LoginScreen,
    },
    {
      path: '/pdf/:accessKey',
      name: 'PdfScreen',
      component: PdfScreen,
      meta: {
        withoutLayoutSections: true,
      },
    },
    {
      path: '/users/request-password-reset',
      name: 'RequestPasswordResetScreen',
      component: RequestPasswordResetScreen,
    },
    {
      path: '/users/reset-password/:token?',
      name: 'ResetPasswordScreen',
      component: ResetPasswordScreen,
    },
    {
      path: '/users/profile',
      name: 'ProfileScreen',
      component: ProfileScreen,
    },
    {
      path: '/users/purchases',
      name: 'PurchasesScreen',
      component: PurchasesScreen,
    },
    {
      path: '/tickets/:cikkcsoportId',
      name: 'TicketsScreen',
      component: TicketsScreen,
    },
    {
      path: '/cart',
      name: 'CartScreen',
      component: CartScreen,
    },
    {
      path: '/payment/registration',
      name: 'PaymentScreen',
      component: PaymentScreen,
      alias: [
        '/payment/invoice',
        '/payment/summary',
      ],
    },
    {
      path: '/payment/finalization',
      name: 'PaymentFinalizationScreen',
      component: PaymentFinalizationScreen,
    },
    {
      path: '/services/down',
      name: 'ServicesDownScreen',
      component: ServicesDownScreen,
    },
    {
      path: '/terms-and-conditions',
      name: 'TermsAndConditionsScreen',
      component: TermsAndConditionsScreen,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyScreen',
      component: PrivacyPolicyScreen,
    },
  ],
});

router.beforeEach(guard);

export default router;
