<template>
  <footer id='footer-section'>
    <div class="cols-row">
      <div class="container hungarospa-container">
        <div class="cols-wrapper">
          <div class="footer-col-1">
            <div class="logo">
              <img src="/static/images/logos/hungarospa-logo_monocolor.svg">
            </div>
            <footer-socials
              :facebookLink="'https://www.facebook.com/hungarospa.hajduszoboszlo'"
              :pinterestLink="'http://www.pinterest.com/Hungarospa'"
              :instagramLink="'http://instagram.com/hungarospa.hajduszoboszlo'"
              :youtubeLink="'http://www.youtube.com/hungarospa1'"
            />
          </div>
          <div class="footer-col-2">
            <ul class="footer-links">
              <li class="link"
                v-for="(link, i) in links" :key="i">
                <a target="_blank" :href="link.link">{{ link.text }}</a>
              </li>
            </ul>
          </div>
          <div class="footer-col-3">
            <ul class="footer-links">
              <li class="link">
                <a>Online jegyvásárlással kapcsolatos ügyintézés:</a>
              </li>
              <li class="link">
                <a>E-mail: onlinejegy@hungarospa.hu</a>
              </li>
              <li class="link">
                <a>Tel: 00-36 70/469-1517 (8-17 óráig)</a>
              </li>
              <li class="link">
                <a></a>
              </li>
            </ul>
          </div>
          <palm-tree-with-man />
        </div>
      </div>
    </div>
    <div class="copyright-row">
      <div class="copyright">
        <div class="container hungarospa-container">
          <div class="left-col">
            <span class="copyright-text">
              {{ $t('footer.copyright.text') }}
            </span>
            <span v-if="apiVersionNumber" class="versions">
              <span class="client">{{ $t('footer.versions.client', { versionNumber: clientVersionNumber }) }}</span>
              <span class="api">{{ $t('footer.versions.api', { versionNumber: apiVersionNumber }) }}</span>
            </span>
          </div>
          <div class="right-col">
            <ul class="copyright-logos">
              <li v-for="(logo, i) in copyrightLogos" :key="i">
                <a :href="getsimpleLink()" target="_blank">
                  <img :src="logo">
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapActions } from 'vuex';
import store from '@/store';
import FooterSocials from '@/components/shared/modules/socials/FooterSocials';
import PalmTreeWithMan from '@/components/shared/elements/illustrations/PalmTreeWithMan';

export default {
  name: 'FooterSection',
  props: {},
  components: {
    FooterSocials,
    PalmTreeWithMan,
  },
  data: () => ({
    apiVersionNumber: undefined,
  }),
  created() {
    this.getApiVersionNumber().then((resp) => {
      this.apiVersionNumber = resp.data.version;
    });
  },
  computed: {
    links() {
      return [
        {
          link: 'https://www.hungarospa.hu/hu/Kapcsolat',
          text: this.$t('footer.links.contact'),
        },
        {
          link: 'https://www.mconet.hu/kapcsolat',
          text: this.$t('footer.links.imprint'),
        },
        {
          link: '/privacy-policy',
          text: this.$t('footer.links.privacyPolicy'),
        },
        {
          link: '/terms-and-conditions',
          text: this.$t('footer.links.generalTerms'),
        },
      ];
    },
    currentYear() {
      const now = new Date();
      return now.getFullYear();
    },
    copyrightLogos() {
      return [
        '/static/images/logos/simplepay-w-bankcards.jpg',
      ];
    },
    clientVersionNumber() {
      return process.env.VUE_APP_VERSION;
    },
  },
  methods: {
    ...mapActions({
      getApiVersionNumber: 'settings/getApiVersionNumber',
    }),
    getsimpleLink() {
      const language = store.getters.langCode;
      if (language === 'hu' || language === undefined) {
        return process.env.VUE_APP_SIMPLE_URL_HU;
      }
      return process.env.VUE_APP_SIMPLE_URL_FOREIGN;
    },
  },
};
</script>

<style lang='scss' scoped>
$copyrightHeight: 50px;

.cols-row {
  background-color: $primaryOrange;
  color: $white;
}

.left-col,
.right-col {
  @media screen and (max-width: $breakpointDownXs) {
    width: 100%;
  }
}

.copyright-row {
  background-color: $white;
  color: $black;
  font-size: 12px;
  font-weight: 500;

  @media screen and (max-width: $breakpointDownXs) {
    font-size: 10px;
  }

  .hungarospa-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $copyrightHeight;

    @media screen and (max-width: $breakpointDownXs) {
      flex-wrap: wrap;
      padding-top: 10px;
      padding-bottom: 10px;
      height: auto;
    }
  }
}

.versions {
  display: block;

  span {
    display: inline-block;
    line-height: 1.2;
    font-size: 11px;
    color: rgba($globalFontColor, 0.6);

    @media screen and (max-width: $breakpointDownXs) {
      font-size: 10px;
    }

    &:first-child {
      margin: 0 10px 0 0;
    }
  }
}

.cols-wrapper {
  padding: 35px 0;
  display: grid;
  grid-template-columns: auto 0.3fr 1fr;
  column-gap: 65px;
  align-items: center;
  position: relative;

  @media screen and (max-width: $breakpointDownXs) {
    grid-template-columns: 1fr;
    row-gap: 20px;
    column-gap: 0;
    padding: 20px 0;
  }

  @media screen and (max-width: $breakpointDownXXs) {
    overflow: hidden;
  }
}

.palm-tree-with-man {
  position: absolute;
  top: -50px;
  right: 20px;

  @media screen and (max-width: $breakpointDownLg) {
    right: -30px;
  }

  @media screen and (min-width: $breakpointDownXs) and (max-width: $breakpointDownMd) {
    display: none;
  }

  @media screen and (max-width: $breakpointDownSm) {
    right: -10px;
  }

  @media screen and (max-width: $breakpointDownXs) {
    bottom: 10px;
    top: auto;
  }

  &::v-deep {
    svg {
      @media screen and (max-width: $breakpointDownXs) {
        height: 180px;
      }
    }
  }
}

.footer-col-1 {
  padding-left: 105px;

  @media screen and (max-width: $breakpointDownLg) {
    padding-left: 0;
  }
}

.logo {
  img {
    @media screen and (max-width: $breakpointDownSm) {
      width: 145px;
    }
  }
}

.footer-col-3 {
  max-width: 210px;
  .link {
    font-size: 12px;
    font-weight: 500;
    margin: 0 0 20px;

    @media screen and (max-width: $breakpointDownXs) {
      margin: 0 0 16px;
    }

    &:last-child {
      margin: 0;

      @media screen and (max-width: $breakpointDownXs) {
        margin: 0;
      }
    }
  }
}

.footer-links {
  list-style-type: none;
  padding: 0;
  margin: 0;

  .link {
    margin: 0 0 20px;

    @media screen and (max-width: $breakpointDownXs) {
      margin: 0 0 16px;
    }

    &:last-child {
      margin: 0;

      @media screen and (max-width: $breakpointDownXs) {
        margin: 0;
      }
    }
  }

  a,
  a:visited {
    color: $white;
    text-decoration: none;
    font-size: 12px;
    line-height: 10px;
    display: block;

    @media screen and (max-width: $breakpointDownXs) {
      font-size: 10px;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.footer-socials {
  margin: 18px 20px 0 0;

  @media screen and (max-width: $breakpointDownXs) {
    display: none;
  }
}

.copyright-logos {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  align-items: center;

  @media screen and (max-width: $breakpointDownXs) {
    margin-top: 10px;
  }

  img {
    max-height: 25px;

    @media screen and (max-width: $breakpointDownXs) {
      max-width: 100%;
    }
  }

  li {
    margin: 0 25px 0 0;

    @media screen and (max-width: $breakpointDownXs) {
      margin-left: auto
    }
  }
}
</style>
