import { fetchData, apiURL } from './api_helper';

const updateInvoiceData = (invoiceId, invoiceData) => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/invoices/${invoiceId}`;

  fetchData(endpointURL, 'PATCH', invoiceData).then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

const addInvoiceData = (invoiceData) => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/invoices/add`;

  fetchData(endpointURL, 'POST', invoiceData).then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

export default {
  updateInvoiceData,
  addInvoiceData,
};
