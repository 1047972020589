import Vue from 'vue';
import Vuex from 'vuex';
import persistedState from 'vuex-persistedstate';

import settings from './modules/settings';
import users from './modules/users';
import cikkcsoports from './modules/cikkcsoports';
import cikks from './modules/cikks';
import cart from './modules/cart';
import invoiceData from './modules/invoiceData';
import orderActions from './modules/orderActions';
import simple from './modules/simple';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
  state: {
    langCode: undefined,
    authToken: undefined,
    cartId: undefined,
    orderId: undefined,
    servicesStatus: false,
    selectedDate: undefined,
  },
  mutations: {
    storeLangCode(state, langCode) {
      state.langCode = langCode;
    },
    storeToken(state, token) {
      state.authToken = token;
    },
    storeCartId(state, id) {
      state.cartId = id;
    },
    setOrderId(state, id) {
      state.orderId = id;
    },
    setServicesStatus(state, status) {
      state.servicesStatus = status;
    },
    saveSelectedDate(state, date) {
      state.selectedDate = date;
    },
  },
  getters: {
    langCode: (state) => state.langCode,
    authToken: (state) => state.authToken,
    cartId: (state) => state.cartId,
    orderId: (state) => state.orderId,
    servicesStatus: (state) => state.servicesStatus,
    getSelectedDate: (state) => state.selectedDate,
  },
  actions: {},
  modules: {
    settings,
    users,
    cikkcsoports,
    cikks,
    cart,
    invoiceData,
    simple,
    orderActions,
  },
  strict: debug,
  plugins: [persistedState({
    key: 'store',
    paths: [
      'langCode',
      'authToken',
      'cartId',
      'orderId',
      'servicesStatus',
      'selectedDate',
    ],
  })],
});

export default store;
