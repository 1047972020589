<template>
  <div class="button-wrapper"
    @mouseover="isDropdownVisible = true" @mouseleave="isDropdownVisible = false">
    <button class="button-with-icon"
      :class="[{ 'active': isActive },
        { 'has-dropdown': dropdownItems }, { 'hovered': isDropdownVisible && dropdownItems }]"
      @click="handleButtonClick"
      >
        <span class="icon" :style="{ maskImage: `url(${iconUrl})` }" />
        <span class="text">{{ text }}</span>
        <icon-arrow-down v-if="dropdownItems" :color="'#3E6FB5'"/>

    </button>
        <dropdown-list v-if="dropdownItems" :items="dropdownItems"
          :isVisible="isDropdownVisible"
          :width="210"
          @clicked="handleDropdownItemClick" />
  </div>
</template>

<script>
export default {
  name: 'ButtonWithIcon',
  props: {
    isActive: {
      type: Boolean,
    },
    iconUrl: {
      type: String,
    },
    text: {
      type: String,
    },
    dropdownItems: {
      type: Array,
      required: false,
    },
  },
  components: {
    'icon-arrow-down': () => import('@/components/shared/elements/icons/IconArrowDown'),
    'dropdown-list': () => import('@/components/shared/elements/partials/DropdownList'),
  },
  data: () => ({
    isDropdownVisible: false,
  }),
  created() {},
  computed: {},
  methods: {
    handleButtonClick(e) {
      if (!e.target.classList.contains('dropdown-item')
        && e.target.tagName !== 'UL' && !(e.target.tagName === 'SPAN' && e.target.classList.length === 0)) {
        this.$emit('clicked');
      }
    },
    handleDropdownItemClick(key) {
      this.$emit('dropdownItemClicked', key);
    },
  },
};
</script>

<style lang='scss' scoped>
$iconSize: 16px;

.button-wrapper {
  position: relative;
  padding-bottom: 15px;
  margin-bottom: -15px;
}

.button-with-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background-color: transparent;
  font-family: $primaryFontFamily;
  opacity: 0.5;
  transition: $transitionBase;
  padding: 9px 12px;
  color: $primaryBlue;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  border-radius: $globalBorderRadius;

  &.has-dropdown {
    padding-left: 25px;
    padding-right: 21px;
  }

  &.active {
    background-color: $primaryBlue;
    color: $white;
    opacity: 1;
    box-shadow: $primaryBlueShadow;

    .icon {
      background-color: $white;
    }

    &.has-dropdown {
      .icon-arrow-down {
        /deep/ path {
          fill: $white;
        }
      }
    }
  }

  &.hovered,
  &:hover {
    opacity: 1;

    &.has-dropdown {
      background-color: $primaryBlue;
      color: $white;
      opacity: 1;
      box-shadow: $primaryBlueShadow;

      .icon {
        background-color: $white;
      }

      .icon-arrow-down {
        /deep/ path {
          fill: $white;
        }
      }
    }
  }
}

.text {
  margin: 2px 0 0;
}

.icon {
  display: inline-block;
  width: $iconSize;
  height: $iconSize;
  background-color: $primaryBlue;
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center;
  margin: 0 7px 0 0;
  transition: $transitionBase;
}

.icon-arrow-down {
  margin: 0 0 0 7px;
}
</style>
