import { fetchData, apiURL } from './api_helper';

const changeValidityDate = (data) => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/orders/${data.id}/changeValidityDate`;

  fetchData(endpointURL, 'PATCH', data.body).then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

const refundOrder = (data) => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/orders/${data.id}/refund`;
  console.log(endpointURL);
  fetchData(endpointURL, 'POST', {}).then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

export default {
  changeValidityDate,
  refundOrder,
};
