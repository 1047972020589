import api from '@/api/orderActions';

const changeValidityDate = (context, data) => new Promise((resolve, reject) => {
  api.changeValidityDate(data)
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

const refundOrder = (context, data) => new Promise((resolve, reject) => {
  api.refundOrder(data)
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

export default {
  changeValidityDate,
  refundOrder,
};
