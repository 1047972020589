import api from '@/api/cikkcsoports';
// import store from '@/store';

const getCikkcsoports = (context) => new Promise((resolve, reject) => {
  api.getCikkcsoports()
    .then((response) => {
      context.commit('SAVE_CIKKCSOPORTS', response);
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

export default {
  getCikkcsoports,
};
