<template>
  <div
    id="modal"
    @click.self="clickOutside"
    :class="[isScaledUpParent ? 'scaled-up-container' : '', { 'blurred-bg': component.bind.hasBlurBackground }, { 'lighter-shadow': component.bind.hasLighterBackground }]"
  >
    <div
      v-if="isMounted"
      class="modal-container"
      ref="innerModal"
      :style="{...modalStyle, backgroundColor: getBackgroundColor}"
    >
      <component
        :class="{scaledUp: isScaledUp}"
        :salesPopupType="component.salesPopupType"
        v-bind:is="component.bind.is"
        v-bind="component.bind"
        v-on="component.on"
      >
        <template slot="modal-header" slot-scope="{ titleText, titleStyle, hasSeparator }">
          <modal-header
            :titleText="titleText"
            :titleStyle="titleStyle"
            :hasSeparator="hasSeparator"
          />
        </template>
      </component>
      <div v-if="showExitIcon" class="icon-close" @click="closeModal">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
          <path
            id="Path_8625"
            class="cls-1"
            data-name="Path 8625"
            d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,
            19,12,13.41,17.59,19,19,17.59,13.41,12Z"
            transform="translate(-5 -5)"
            fill="#aeb7bf"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import ModalHeader from '@/components/shared/elements/modals/ModalHeader';

export default {
  name: 'ModalComponent',
  components: {
    'modal-header': ModalHeader,
  },
  props: {
    component: {
      type: Object,
      required: true,
    },
    width: {
      type: String,
      required: false,
      default: '50%',
    },
    height: {
      type: String,
      required: false,
    },
    overflow: {
      type: String,
      required: false,
    },
    hasExit: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getBackgroundColor() {
      return this.component.backgroundColor || 'white';
    },
    isScaledUp() {
      return this.component.isScaledUp;
    },
    isScaledUpParent() {
      return (
        this.component.isScaledUp
        && (navigator.userAgent.includes('Mozilla')
          || navigator.userAgent.includes('Firefox'))
      );
    },
    showExitIcon() {
      if (this.component.bind.hasExit !== undefined) {
        return this.component.bind.hasExit;
      }
      return this.hasExit;
    },
  },
  data() {
    return {
      modalStyle: {
        width: this.component.width,
        height: this.component.height || 'unset',
        maxHeight: this.component.maxHeight || 'none',
        overflow: this.component.overflow || 'visible',
      },
      upperPicture: null,
      isMounted: false,
    };
  },
  methods: {
    clickOutside() {
      if (this.component.bind.closeable) {
        this.closeModal();
      }
    },
    closeModal() {
      this.$eventBus.closeModal();
      document.querySelector('#app').classList.remove('blurred');
    },
    disableScroll() {
      document.body.style.overflow = 'hidden';
    },
    enableScroll() {
      document.body.style.overflow = null;
    },
  },
  mounted() {
    this.isMounted = true;
    this.upperPicture = this.component.upperPicture;
    if (this.component.bind.hasBlurBackground) {
      document.querySelector('#app').classList.add('blurred');
    }
    this.disableScroll();
  },
  beforeDestroy() {
    this.enableScroll();
    document.querySelector('#app').classList.remove('blurred');
  },
};
</script>

<style lang="scss" scoped>
#modal {
  background-color: rgba($black, 0.9);
  position: fixed;
  z-index: z('modal');
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  // overflow: hidden;

  &.lighter-shadow {
    background-color: rgba($black, 0.3);
  }

  &.blurred-bg {
    background-color: rgba(97, 97, 97, 0.48);
  }

  .modal-container {
    max-width: 100vw;
    max-height: 100vh;
    transition: all 0.4s ease-in-out;
    z-index: 20;
    overflow: hidden;
    box-shadow: $lightDropdownShadow;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: $globalBorderRadius;

    .scaledUp {
      @media (min-width: 1500px) {
        $zoomRate: 1.25;
        zoom: $zoomRate;
      }
    }
  }
  .scaled-up-container {
    @media (min-width: 1500px) {
      -moz-transform: scale(1.25);
    }
  }
  .icon-close {
    cursor: pointer;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 16px;
    right: 16px;
    line-height: normal;

    /deep/ .cls-2 {
      fill: transparent;
    }
    /deep/ .cls-1 {
      fill: #aeb7bf;
      transition: $transitionBase;
    }
    &:hover /deep/ .cls-1 {
      fill: $black;
    }
  }
}
</style>
