<template>
  <div id='language-selector'
  @mouseover="handleDropdownVisibility('over')" @mouseleave="handleDropdownVisibility('leave')">
    <div class="selected-language">
      <span class="lang-flag"><img :src="languages[selectedLangIndex].imgUrl"></span>
      <span class="lang-text">{{ languages[selectedLangIndex].text }}</span>
      <icon-arrow-down :color="'#000'" />
    </div>
    <dropdown-list :isVisible="isDropdownVisible" :width="124">
      <li v-for="(item, i) in languages" :key="i" @click="handleLangSelection(item.key)">
        <span class="flag"><img :src="item.imgUrl"></span>
        <span class="text">{{ item.text }}</span>
      </li>
    </dropdown-list>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import store from '@/store';

import IconArrowDown from '@/components/shared/elements/icons/IconArrowDown';
import DropdownList from '@/components/shared/elements/partials/DropdownList';

export default {
  name: 'LanguageSelector',
  props: {},
  components: {
    IconArrowDown,
    DropdownList,
  },
  data: () => ({
    selectedLangIndex: 0,
    isDropdownVisible: false,
  }),
  created() {
    this.setSelectedLangIndex();
  },
  computed: {
    languages() {
      return [
        {
          key: 'hu',
          text: this.$t('navbar.languageSelector.languages.hungarian'),
          imgUrl: '/static/images/flags/flag-hu.png',
        },
        {
          key: 'de',
          text: this.$t('navbar.languageSelector.languages.german'),
          imgUrl: '/static/images/flags/flag-de.png',
        },
        {
          key: 'en',
          text: this.$t('navbar.languageSelector.languages.english'),
          imgUrl: '/static/images/flags/flag-en.png',
        },
        {
          key: 'pl',
          text: this.$t('navbar.languageSelector.languages.poland'),
          imgUrl: '/static/images/flags/flag-pl.png',
        },
        {
          key: 'ru',
          text: this.$t('navbar.languageSelector.languages.russian'),
          imgUrl: '/static/images/flags/flag-ru.png',
        },
        {
          key: 'ro',
          text: this.$t('navbar.languageSelector.languages.romanian'),
          imgUrl: '/static/images/flags/flag-ro.png',
        },
        {
          key: 'ua',
          text: this.$t('navbar.languageSelector.languages.ukrainian'),
          imgUrl: '/static/images/flags/flag-ua.png',
        },
        {
          key: 'cz',
          text: this.$t('navbar.languageSelector.languages.czech'),
          imgUrl: '/static/images/flags/flag-cz.png',
        },
        {
          key: 'sk',
          text: this.$t('navbar.languageSelector.languages.slovak'),
          imgUrl: '/static/images/flags/flag-sk.png',
        },
      ];
    },
    storedLanguage() {
      return store.getters.langCode;
    },
  },
  watch: {
    storedLanguage() {
      this.setSelectedLangIndex();
    },
  },
  methods: {
    ...mapActions({
      storeLangCode: 'settings/storeLangCode',
    }),
    setMomentLocale(langKey) {
      this.$moment.locale(langKey);
    },
    handleLangSelection(langKey) {
      const needToReload = this.$i18n.locale !== langKey;

      this.$i18n.locale = langKey;

      this.storeLangCode(langKey);

      this.setMomentLocale(langKey);

      const langIndex = this.languages.findIndex((x) => x.key === this.$i18n.locale);

      if (langIndex !== this.selectedLangIndex) {
        this.selectedLangIndex = langIndex;
      }

      this.isDropdownVisible = false;

      if (needToReload) {
        setTimeout(() => {
          this.$router.go(0);
        }, 800);
      }
    },
    handleDropdownVisibility(state) {
      switch (state) {
        case 'over':
          this.isDropdownVisible = true;
          this.$emit('langDropdownOpened'); // to avoid multiple dropdown visibility problem on mobile!
          break;
        case 'leave':
          this.isDropdownVisible = false;
          break;
        default:
          break;
      }
    },
    setSelectedLangIndex() {
      this.selectedLangIndex = this.languages.findIndex((x) => x.key === this.$i18n.locale);
    },
  },
};
</script>

<style lang='scss' scoped>
$selectedLangFlagSize: 23px;

#language-selector {
  position: relative;
  padding-bottom: 15px;
  margin-bottom: -15px;
}

.selected-language {
  display: flex;
  align-items: center;
  cursor: pointer;

  .lang-flag {
    width: $selectedLangFlagSize;
    height: $selectedLangFlagSize;
    border-radius: 100%;
    background-color: #ccc;
    margin: 0 10px 0 0;
    display: flex;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .lang-text {
    color: $black;
    font-weight: 700;
    margin: 0 5px 0 0;
    text-transform: uppercase;
    font-size: 12px;

    @media screen and (max-width: $breakpointDownMd) {
      display: none;
    }
  }

  .icon-arrow-down {
    @media screen and (max-width: $breakpointDownMd) {
      display: none;
    }
  }
}

.dropdown-list {
  &::v-deep {
    li {
      padding: 0 0 0 50px;
      line-height: 19px;
      display: flex;
      align-items: center;
      transition: $transitionBase;
      color: $globalFontColor;
      font-weight: 500;
      font-size: 12px;
      cursor: pointer;
      position: relative;
      margin: 0 0 8px;

      &:last-child {
        margin: 0;
      }

      &:hover {
        background-color: $primaryBlue;
        color: $white;
      }

      .flag {
        position: absolute;
        top: -2px;
        left: 18px;
        width: $selectedLangFlagSize;
        height: $selectedLangFlagSize;
        border-radius: 100%;
        display: inline-block;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;
        }
      }
    }
  }
}
</style>
